import { Button, Center, Loader, Paper, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { ScreenContainer } from 'components/ScreenContainer/ScreenContainer';
import { useQuery } from '@tanstack/react-query';
import { ErrorNotification, ErrorNotificationTypes, isValidErrorNotificationType } from 'components/ErrorNotification/ErrorNotification';

type PriceData = {
  data: {
    unit_amount: number;
    currency: string;
    error?: string;
  };
};

// TODO: Load locale from config service instead of relying on currency
function currencyToIntlLocale(currency: string) {
  switch (currency) {
    case 'usd':
      return 'en-US';
    case 'eur':
      return 'de-DE';
    case 'nok':
      return 'nb-NO';
    default:
      return 'en-US';
  }
}

export const PaymentPage = () => {
  const {
    other: { spacing },
    colors,
  } = useMantineTheme();
  const { t } = useTranslation();
  const { state } = useParams();
  const [isSaving, setIsSaving] = useState(false);

  const { data, isLoading, error } = useQuery<PriceData, AxiosError<{ error: string }>>({ queryKey: ['price'], queryFn: () => axios.get(`/api/payment/pricing?state=${state}`) });
  const { data: price } = data || {};

  const goToCheckout = useCallback(() => {
    const beginCheckout = async () => {
      setIsSaving(true);
      const result = await axios.post('/api/payment/checkout', {
        state,
      });
      setIsSaving(false);
      window.location.href = result.data.url;
    };
    beginCheckout();
  }, [state]);

  const formattedPrice = useMemo(() => {
    if (!price) return '';
    return Intl.NumberFormat(currencyToIntlLocale(price.currency), {
      style: 'currency',
      currency: price.currency,
    }).format(price.unit_amount / 100);
  }, [price]);

  const responseError = error?.response?.data.error;

  return (
    <>
      {responseError && isValidErrorNotificationType(responseError) ? <ErrorNotification type={responseError as ErrorNotificationTypes} /> : <></>}
      <ScreenContainer
        center
        space={`0px ${spacing(6)}px`}
        sx={{
          backgroundColor: colors.lime[5],
        }}
      >
        <Center style={{ width: '100%', height: '100vh' }}>
          <Stack spacing={spacing(12)}>
            <Title align="center" style={{ maxWidth: 500 }}>
              {t('payment_title')}
            </Title>
            <Center>
              {!error && (
                <Paper shadow="lg" p="md" style={{ maxWidth: 200 }}>
                  {isLoading && <Loader />}
                  {!isLoading && price && (
                    <Stack spacing={spacing(4)}>
                      <Text size="lg">{t('subscription_title')}</Text>
                      <Text size="sm">{t('subscription_info')}</Text>
                      <Text size="md">
                        {formattedPrice} {t('yearly')}
                      </Text>
                    </Stack>
                  )}
                </Paper>
              )}
            </Center>
            <Button size="md" color="black" onClick={goToCheckout} loading={isSaving} disabled={isSaving}>
              {t('to_payment')}
            </Button>
          </Stack>
        </Center>
      </ScreenContainer>
    </>
  );
};
