import { TenantCodePage } from 'pages/TenantCodePage/TenantCodePage';
import { PaymentPage } from 'pages/Payment';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './styles/fonts.css';
import './styles/styles.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<TenantCodePage />} />
        <Route path="/payment/:state" element={<PaymentPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
