import { Notification, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const errorNotificationTypes = [
  'no-service',
  'system-error',
  'state-not-found',
  'login-already-completed',
  'payment-cancelled',
  'missing-state',
  'login-not-found',
  'terminal-not-found',
  'product-not-found',
] as const;
export type ErrorNotificationTypes = (typeof errorNotificationTypes)[number];

export const isValidErrorNotificationType = (value?: string): value is ErrorNotificationTypes => {
  return (errorNotificationTypes as readonly string[]).includes(value ?? '');
};

export const ErrorNotification = ({ type, closeButton = false }: { type: ErrorNotificationTypes; closeButton?: boolean }) => {
  const {
    other: { spacing },
  } = useMantineTheme();
  const { t } = useTranslation();

  const errorText = useMemo(() => {
    switch (type) {
      case 'no-service':
        return (
          <>
            <Text size="sm" weight={600}>
              {t('error_coverage1')}
            </Text>
            <Text size="sm" weight={400}>
              {t('error_coverage2')}
            </Text>
          </>
        );
      case 'state-not-found':
        return (
          <>
            <Text size="sm" weight={600}>
              {t('error_state1')}
            </Text>
            <Text size="sm" weight={400}>
              {t('error_state2')}
            </Text>
          </>
        );
      case 'payment-cancelled':
        return (
          <>
            <Text size="sm" weight={600}>
              {t('error_payment1')}
            </Text>
            <Text size="sm" weight={400}>
              {t('error_payment2')}
            </Text>
          </>
        );
      case 'login-already-completed':
        return (
          <>
            <Text size="sm" weight={600}>
              {t('error_login1')}
            </Text>
            <Text size="sm" weight={400}>
              {t('error_login2')}
            </Text>
          </>
        );
      case 'terminal-not-found':
        return (
          <>
            <Text size="sm" weight={600}>
              {t('error_terminal1')}
            </Text>
            <Text size="sm" weight={400}>
              {t('error_terminal2')}
            </Text>
          </>
        );
      case 'product-not-found':
        return (
          <>
            <Text size="sm" weight={600}>
              {t('error_no_price1')}
            </Text>
            <Text size="sm" weight={400}>
              {t('error_no_price2')}
            </Text>
          </>
        );
      case 'system-error':
      default:
        console.error(`Unknown error type: ${type}`);
        return (
          <>
            <Text size="sm" weight={600}>
              {t('error_system1')}
            </Text>
            <Text size="sm" weight={400}>
              {t('error_system2')}
            </Text>
          </>
        );
    }
  }, [type, t]);

  return (
    <>
      <Notification color="error" disallowClose={!closeButton} title={<Stack spacing={spacing(1)}>{errorText}</Stack>} />
    </>
  );
};
