import { Button, Center, Group, Input, InputWrapper, Space, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import axios, { AxiosError } from 'axios';
import { ErrorNotification, ErrorNotificationTypes, isValidErrorNotificationType } from 'components/ErrorNotification/ErrorNotification';
import { Popover } from 'components/Popover/Popover';
import { ScreenContainer } from 'components/ScreenContainer/ScreenContainer';
import { Maybe } from 'monet';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { QuestionMarkIcon } from 'resources/icons/QuestionMark';

export const TenantCodePage = () => {
  const {
    other: { spacing },
    colors,
  } = useMantineTheme();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const toggleDrawer = () => setOpenDrawer(!openDrawer);
  const urlParams = new URLSearchParams(window.location.search);
  const state = urlParams.get('state');
  // redirect_uri ex: /api/oauth/callback?state=somestate
  const redirect = decodeURI(urlParams.get('redirect_uri') as string);
  const login_hint = urlParams.get('login_hint');
  /* Getting userId and app language from login_hint (format: "deviceId_language")*/
  const [userId, language, code] = login_hint?.split('-') ?? ['', undefined, undefined];

  const paymentStatus = urlParams.get('payment');

  const [tenantCode, setTenantCode] = useState<string>(code || '');
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<'invalid-input' | ErrorNotificationTypes>();
  const { t, i18n } = useTranslation();

  // If the protocol is https then we assume that it's hosted online, and thus we redirect to the API which is hosted on the same domain
  // However, if the protocol is http then we redirect to the test environment
  const redirDomain = window.location.protocol === 'https:' ? '' : 'https://tenant-auth.test-1.carrot.tech';

  useEffect(() => {
    if (paymentStatus === 'cancelled') {
      setError('payment-cancelled');
    }
  }, [paymentStatus]);

  useEffect(() => {
    // If login_hint sends language -> change to it
    if (language && i18n.languages.includes(language)) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    setError(undefined);

    Maybe.fromFalsy(tenantCode?.length).cata(
      () => {
        setLoading(false);
        setError('invalid-input');
      },
      (_) => {
        setLoading(true);
        axios
          .post(
            `/api/login`,
            {
              tenantCode: tenantCode.toUpperCase(),
              userId,
              state,
            },
            {
              maxRedirects: 0,
            }
          )
          .then((result) => {
            return window.location.replace(result.data?.redirect || `${redirDomain}${redirect}`);
          })
          .catch((err: AxiosError) => {
            console.log(err);
            if (err.response?.status === 404) {
              setError('state-not-found');
            } else if (err.response?.status === 409) {
              setError('login-already-completed');
            } else if (err.code === 'ERR_NETWORK' || err.response?.status === 0) {
              setError('no-service');
            } else if (err.response?.status && err.response?.status >= 500) {
              setError('system-error');
            } else {
              setError('invalid-input');
            }
            setLoading(false);
          });
      }
    );
  };

  const onChangeTenantCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const res = e.target.value;
    if (res.match(/^[a-zA-Z0-9]*$/)) {
      setError(undefined);
      setTenantCode(res);
    }
  };

  return (
    <>
      {isValidErrorNotificationType(error) ? <ErrorNotification type={error as ErrorNotificationTypes} /> : <></>}
      <Popover opened={openDrawer} toggle={toggleDrawer}>
        <Trans
          t={t}
          i18nKey="popover_text"
          components={{
            h3: <Title order={3} mt="xl" mb="sm" />,
            p: <Text size="sm" mb="sm" />,
            a: <a href="mailto:support@carrot.tech" aria-label="Ask for help" />,
          }}
        />
      </Popover>
      <ScreenContainer
        center
        space={`0px ${spacing(6)}px`}
        sx={{
          backgroundColor: colors.lime[5],
        }}
      >
        <form onSubmit={submitForm}>
          <Center style={{ width: '100%', height: '100vh' }}>
            <Stack spacing={spacing(12)}>
              <Title align="center">{t('welcome_title')}</Title>

              <Stack spacing="xs" align="center">
                <InputWrapper
                  label={
                    <Stack spacing={spacing(4)}>
                      <Group spacing={spacing(3)} onClick={toggleDrawer}>
                        <Text size="sm" weight={500} color={error === 'invalid-input' ? colors.error[1] : undefined}>
                          {t('company_code')}
                        </Text>
                        <QuestionMarkIcon />
                      </Group>
                      <Space h={0} />
                    </Stack>
                  }
                >
                  <Input
                    type="text"
                    lang="en-GB"
                    maxLength={9}
                    onChange={onChangeTenantCode}
                    value={tenantCode}
                    invalid={error === 'invalid-input'}
                    autoCapitalize="characters"
                    enterKeyHint="send"
                    sx={{
                      input: {
                        height: '3.5rem',
                        width: '100%',
                        fontSize: 36,
                        letterSpacing: '0.5rem',
                        textTransform: 'uppercase',
                        fontFamily: 'DMMonoLight',
                        borderRadius: 0,
                        textAlign: 'center',
                      },
                    }}
                  />
                </InputWrapper>

                <div style={{ width: '100%', height: '1.25rem' }}>
                  {error === 'invalid-input' ? (
                    <Text size="xs" color={colors.error[1]} align="left" style={{ alignSelf: 'flex-start' }}>
                      <strong>{t('error_unknown_company_code1')}. </strong>
                      {t('error_unknown_company_code2')}.
                    </Text>
                  ) : (
                    <></>
                  )}
                </div>

                <Center style={{ width: '100%' }}>
                  <Button loading={loading} type="submit" size="md" color="black" disabled={!tenantCode?.length || tenantCode?.length < 9 || loading || !!error}>
                    {loading ? '' : <>{t('log_in')}</>}
                  </Button>
                </Center>
              </Stack>
              <Space h={0} />
            </Stack>
          </Center>
        </form>
      </ScreenContainer>
    </>
  );
};
